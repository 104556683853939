"use client";
import React from "react";
import "./HomeHero.scss";
import { Container, Row, Col } from "react-bootstrap";

const HomeVideo = () => {
  return (
        <div className="hero-wrapper">
          <Container >
            <div className="inner-header flex"></div>
            <div>
              <svg
                className="waves"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
              >
                <defs>
                  <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                  />
                </defs>
                <g className="parallax">
                  <use href="#gentle-wave" x="48" y="0" fill="#53a2a7" />
                  <use href="#gentle-wave" x="48" y="2" fill="#317f84" />
                  <use href="#gentle-wave" x="48" y="5" fill="#196064" />
                  <use href="#gentle-wave" x="48" y="7" fill="#134b4e" />
                </g>
              </svg>
            </div>

            <Row className="hero-section onboarding-section">
              <Col md={6} className="hero-text-container primary">
                  <h1 className="klean-leaf-title">Klean Leaf</h1>
                  <h4> Sustainable Laundry Detergent Sheets Manufacturer for Your Business.</h4>
              </Col>

              <Col md={6} className="hero-text-container hero-metrics primary">
                <div className="metrics-row">
                  <div className="metric-item">
                    <h3>200+</h3>
                    <p>Clients</p>
                  </div>
                  <div className="split-line"></div>
                  <div className="metric-item">
                    <h3>10+</h3>
                    <p>Years</p>
                  </div>
                  <div className="split-line"></div>
                  <div className="metric-item">
                    <h3>100%</h3>
                    <p>Plastic-Free</p>
                  </div>
                </div>
              </Col>


            </Row>


          </Container>
        </div>
        
        
  );
};

export default HomeVideo;
