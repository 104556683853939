import React, { useEffect, useRef } from "react";
import "./HomeWhy.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "antd";
import { Image } from "react-bootstrap";

import trusted from "../../../Assets/why/trusted.png";
import support from "../../../Assets/why/support.png";
import transparency from "../../../Assets/why/transparency.png";
import samples from "../../../Assets/why/samples.png";
import moq from "../../../Assets/why/moq.png";
import consultation from "../../../Assets/why/consultation.png";


const HomeVideo = () => {

    const upgradeImageRef = useRef(null);
    const cardRefs = useRef([]);

    useEffect(() => {
        const handleScrollAnimation = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
            }
        });
        };

        const observer = new IntersectionObserver(handleScrollAnimation, {
        threshold: 0.1,
        });

        if (upgradeImageRef.current) {
        observer.observe(upgradeImageRef.current);
        }

        cardRefs.current.forEach((card) => {
        if (card) {
            observer.observe(card);
        }
        });

        return () => {
        if (upgradeImageRef.current) {
            observer.unobserve(upgradeImageRef.current);
        }
        cardRefs.current.forEach((card) => {
            if (card) {
            observer.unobserve(card);
            }
        });
        };
  }, []);


  return (
    <Container fluid className="home-why white">
        <Container className="inner-container left">
            <Row className="justify-content-center features-title pb-4">
                <h2>Why choose Funda Sourcing?</h2>
            </Row>

            <Row className="left">
                <Col md={6} className="equal-height-cards">
                <Card className="slide-up" ref={(el) => (cardRefs.current[0] = el)}>
                    <h4>Trusted by Leading Brands</h4>
                    <div className="flex left">
                        <Image className="feature-icon" src={trusted} alt="sourcing company" />
                        <p>
                        Preferred supplier for top detergent sheet brands, known for quality and innovation.

                        </p>
                    </div>
                </Card>
                </Col>

                <Col md={6} className="equal-height-cards">
                <Card className="slide-up" ref={(el) => (cardRefs.current[1] = el)}>
                    <h4> Exceptional Local Support</h4>
                    <div className="flex left">
                        <Image className="feature-icon" src={support} alt="skill" />
                        <p>
                        With a dedicated team based in Columbus, Ohio, we make communication seamless and efficient - just a call or meeting away.

                        </p>
                    </div>
                </Card>
                </Col>

                <Col md={6} className="equal-height-cards">
                <Card className="slide-up" ref={(el) => (cardRefs.current[2] = el)}>
                    <h4>Certifications and Transparency</h4>
                    <div className="flex left">
                        <Image className="feature-icon" src={transparency} alt="source from China" />
                        <p>
                        ISO 9001, ISO 22716 GMP, and OECD 301B certified, with MSDS documentation for transparency.
                        </p>
                    </div>
                </Card>
                </Col>

                <Col md={6} className="equal-height-cards">
                <Card className="slide-up" ref={(el) => (cardRefs.current[3] = el)}>
                    <h4>Free Samples and Packaging Support</h4>
                    <div className="flex left">
                        <Image className="feature-icon" src={samples} alt="bear" />
                        <p>
                        Complimentary product and packaging samples customized to your brand.
                        </p>
                    </div>
                </Card>
                </Col>

                <Col md={6} className="equal-height-cards">
                <Card className="slide-up" ref={(el) => (cardRefs.current[4] = el)}>
                    <h4>Low Minimum Order Quantity (MOQ)</h4>
                    <div className="flex left">
                        <Image className="feature-icon" src={moq} alt="bear" />
                        <p>
                        Flexible minimums to support new product launches or market testing.
                        </p>
                    </div>
                </Card>
                </Col>

                <Col md={6} className="equal-height-cards">
                <Card className="slide-up" ref={(el) => (cardRefs.current[5] = el)}>
                    <h4>Comprehensive Free Consultation</h4>
                    <div className="flex left">
                        <Image className="feature-icon" src={consultation} alt="bear" />
                        <p>
                        Free consultation to develop premium, eco-friendly detergent sheets for your brand.
                        </p>
                    </div>
                </Card>
                </Col>
            </Row>
        </Container>
  </Container>
        
        
  );
};

export default HomeVideo;
