import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home-main.svg";
import singleSheet from "../../Assets/single-sheet.png";
import oceanPlastic from "../../Assets/ocean-plastic.png";
import logoRing from "../../Assets/ring.png";
import logoLeaf from "../../Assets/leaf.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import HomeWhy from "./HomeWhy/HomeWhy";
import HomeCompact from "./HomeCompact/HomeCompact";

function Home2() {

  return (
    <>
      <Container fluid className="home-about-section primary-bg left">
        <Container className="inner-container">
          <Row>
            <Col md={4}>
                <Tilt>
                  <img src={myImg} className="img-fluid" alt="laundry detergent strips manufacturer" />
                </Tilt>
            </Col>
            <Col md={4} className="numbered-items-column white">
              <div className="numbered-item">
                <div className="circle primary">01</div>
                <h4>Low Minimums</h4>
              </div>
              <div className="numbered-item">
                <div className="circle primary">02</div>
                <h4>Fast Turnaround Time</h4>
              </div>
              <div className="numbered-item">
                <div className="circle primary">03</div>
                <h4>Complimentary Design</h4>

              </div>
            </Col>

            <Col md={4} className="sample-sheet">
              <Tilt>
                <img src={singleSheet} className="img-fluid" alt="laundry detergent strips manufacturer" />
              </Tilt>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="home-about-section" >
        <Container className="inner-container left primary">
          <h2 className="home-about-title left">Tailored Laundry Solutions for Your Brand</h2>
          <Row className="home-about-content">
    
            <Col md={8} className="left home-about-feature mt-4">
              <p>
              We offer private labeling, bulk manufacturing, and customized formulas to align with your brand’s unique needs. From scent profiles to eco-packaging options, our solutions ensure you stand out in the market while staying sustainable.
              </p>
            </Col>
            <Col md={4}>
            <div className="rotate-logo-wrapper">
              <img src={logoLeaf} className="img-fluid leaf" alt="laundry detergent strips manufacturer" />
              <img src={logoRing} className="img-fluid ring" alt="laundry detergent strips manufacturer" />
            </div>
            </Col>
          </Row>
          <a href="/contact" className="primary-btn left">Contact Now</a>
        </Container>
      </Container>

      <HomeWhy />
      <HomeCompact />

      <Container fluid className="home-about-section home-carousel" >
        <Container>
          <div className="carousel-container">
            <div className="carousel-slide">
              <div className="carousel-item">Eco-Friendly Solutions</div>
              <div className="carousel-item">Sustainable Innovation</div>
              <div className="carousel-item">Certified Quality Standards</div>
              <div className="carousel-item">Biodegradable Products</div>
              <div className="carousel-item">Reliable Supply Chain</div>
              <div className="carousel-item">Customizable Packaging</div>
              <div className="carousel-item">Global Reach</div>
              <div className="carousel-item">Cutting-Edge R&D</div>
              <div className="carousel-item">Trusted Manufacturing Partner</div>
              <div className="carousel-item">Safe and Effective Formulations</div>
              <div className="carousel-item">Uncompromised Quality Control</div>
              <div className="carousel-item">Environmentally Responsible Practices</div>
              <div className="carousel-item">Advanced Production Facilities</div>
            </div>
          </div>
        </Container>
      </Container>

      <Container fluid className="home-about-section primary left" >
        <Container>
          <h2>
            Our Responsibility:
          </h2>
          <img src={oceanPlastic} className="img-fluid plastic-ocean" alt="laundry detergent strips manufacturer" />
          <p className="mt-5">
            Each year, over 700 million plastic laundry jugs end up in landfills
            and oceans, contributing to a growing environmental crisis. At Klean
            Leaf, we believe in a better way. Our mission is to provide you with a
            powerful, effective laundry solution that reduces plastic waste and
            leaves our planet cleaner for future generations.{" "}
          </p>

          <Row>
            <Col md={12} className="home-about-social center primary">
              <h2>FIND US ON</h2>
              <p>Feel free to connect with us</p>
              <ul>
                <li className="social-icons center">
                  <a
                    href="https://twitter.com/kleanleaf"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiOutlineTwitter />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.facebook.com/kleanleaf/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiFillFacebook />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.instagram.com/kleanleaf"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
              </ul>
              <a
                href="mailto:hello@kleanleaf.us"
                style={{ textDecoration: "none", color: "#134b4e" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>hello@kleanleaf.us</p>
              </a>
              <a
                href="mailto:kleanleaf@gmail.coms"
                style={{ textDecoration: "none", color: "#134b4e" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>kleanleaf@gmail.com</p>
              </a>
              <a
                href="tel:614-906-5129"
                style={{ textDecoration: "none", color: "#134b4e" }}
              >
                <p>614.906.5129</p>
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default Home2;
