import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";
import {
  MdOutlineLocalLaundryService,
  MdOutlineDesignServices,
  MdProductionQuantityLimits
} from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { BsChatRightHeart } from "react-icons/bs";
import { BiPackage, BiCheckCircle } from "react-icons/bi";
import Rellax from 'rellax';
import numberOne from "../../Assets/numbers/number-1.png";
import numberTwo from "../../Assets/numbers/number-2.png";
import numberThree from "../../Assets/numbers/number-3.png";
import numberFour from "../../Assets/numbers/number-4.png";
import numberFive from "../../Assets/numbers/number-5.png";
import numberSix from "../../Assets/numbers/number-6.png";
import numberSeven from "../../Assets/numbers/number-7.png";

function Service() {
  useEffect(() => {
    // Check if elements with the class 'rellax' exist before initializing Rellax
    const elements = document.querySelectorAll('.rellax');
    if (elements.length > 0) {
      const rellax = new Rellax('.rellax');
  
      // Cleanup function to destroy the instance if it exists
      return () => {
        if (rellax && typeof rellax.destroy === 'function') {
          rellax.destroy();
        }
      };
    }
  }, []);
  
  const serviceSteps = [
    {
      number: numberOne,
      title: "Free Samples",
      icon: <MdOutlineLocalLaundryService />,
      description: "Reach out to us via phone or email to request a complimentary sample. We are confident that once you experience its effectiveness, you will be delighted with the results."
    },
    {
      number: numberTwo,
      title: "Pick a Scent",
      icon: <BsChatRightHeart />,
      description: "From the more than 10 scent samples we sent, please choose the one that best aligns with your brand. Alternatively, we can work on creating a customized fragrance for you."
    },
    {
      number: numberThree,
      title: "Artwork",
      icon: <MdOutlineDesignServices />,
      description: "Once you've finished your package design, send it over in both .ai and .pdf formats, and we'll get to work on the next step."
    },
    {
      number: numberFour,
      title: "Package Samples",
      icon: <BiPackage />,
      description: "We’ll collaborate with our packaging team to create package samples. The first two paper bag versions are free; additional changes may incur fees."
    },
    {
      number: numberFive,
      title: "Approval",
      icon: <BiCheckCircle />,
      description: "Once you’re happy with the product, scent, and packaging, we’ll be ready to start your order whenever you give the go-ahead."
    },
    {
      number: numberSix,
      title: "MOQ",
      icon: <MdProductionQuantityLimits />,
      description: "The minimum order quantity is 150,000 sheets. You can decide how many sheets to include in each pack. You can decide how many sheets to include in each pack. For example, 30 sheets per pack means an MOQ of 5,000 units; for 15 sheets per pack, the MOQ would be 10,000 units."
    },
    {
      number: numberSeven,
      title: "Full Service",
      icon: <TbTruckDelivery />,
      description: "We handle everything from manufacturing to packaging, customs, freight, and direct delivery to your warehouse."
    }
  ];

  return (
    <div className="service">
      <Container fluid className="service-page primary page-top-settings">
        <Container>
          <Row>
            <h1>Laundry Detergent Sheets Manufacturer</h1>
            <h2 className="page-title">
              Our Service
              <p>Order process</p>
            </h2>
          </Row>
          <Row>
            {serviceSteps.map((step, index) => (
              <Col md={4} sm={6} xs={12} key={index} className={`mb-4 d-flex align-items-stretch ${index === serviceSteps.length - 1 ? 'align-self-start' : ''}`}>
                <Card className="service-card w-100">
                  <Card.Body>
                    <div className="card-top">
                      <img src={step.number} alt={`Step ${index + 1}`} className="step-number" />
                      <div className="step-icon">{step.icon}</div>
                    </div>
                    <Card.Title className="step-title">{step.title}</Card.Title>
                    <div className="step-description">
                      {step.description}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <a href="tel:614-906-5129" className="primary-btn">
              <FiPhoneCall />&nbsp;&nbsp;Call Us For More Info
            </a>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Service;
