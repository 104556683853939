"use client";
import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Card } from "antd";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo-white.svg";
import "./Footer.scss";
import { Image } from "react-bootstrap";
import axios from "axios";

import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";


const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!email) {
      alert("Please enter a valid email address.");
      return;
    }

    const data = JSON.stringify({ email });

    const config = {
      method: "post",
      url: "https://api.limee.ai/api/mailchimp/subscribe/",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    try {
      await axios.request(config);
      setEmail("");
      alert("Thank you for subscribing to our newsletter!");
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response?.data?.includes("already subscribed")
          ? "You are already subscribed to our newsletter!"
          : "Failed to subscribe. Please try again later.";
      alert(errorMessage);
    }
  };

  return (
    <Container fluid className="footer">
      <Container>
        <Row className="alignEnd">
          <Col md={6} sm={6} xs={12} lg={6} className="footer-section">
            <h4>
              <Image
                width={100}
                height={100}
                src={logo}
                className="img-fluid logo"
                alt="logo"
              /> 
            </h4><h1>Klean Leaf</h1>
            <p className="footer-subtitle">Laundry Detergent Sheets Manufacturer</p>
          </Col>
          <Col md={3} sm={3} xs={6} lg={3} className="footer-section">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/service">Service</Link>
              </li>
              <li>
                <Link to="/production">Production</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={3} xs={6} lg={3} className="footer-section">
            <ul>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </Col>
{/*  
          <Col md={12} sm={12} lg={4} className="footer-section">
            <Card className="newsletter">
              <h2>Stay in the loop</h2>
              <p>Subscribe to our newsletter to stay up to date.</p>
              <Form>
                <Form.Group controlId="formEmailAddress">
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <button
                  onClick={handleSubscribe}
                  className="primary-btn footer-btn"
                  type="submit"
                >
                  Subscribe
                </button>
              </Form>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col className="text-center">
            {/* Add your footer note here */}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={6} xs={12} lg={6} className="footer-copywright">
          <a href="/" className="design white" style={{ textDecoration: "none" }}><p>Designed by Klean Leaf</p></a>
          </Col>
          <Col md={3} sm={6} xs={12} lg={3} className="footer-copywright">
            <a
              href="mailto:hello@kleanleaf.us"
              style={{ textDecoration: "none" }}
              target="_blank"
              className="white"
              rel="noopener noreferrer"
            >
              <p>hello@kleanleaf.us</p>
            </a>
          </Col>
          
          <Col md={3} sm={6} xs={12} lg={3} className="footer-body">
            <ul className="footer-icons">
              <li className="social-icons">
                <a
                  href="https://twitter.com/kleanleaf"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/kleanleaf/"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kleanleaf"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
