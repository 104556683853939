import React from "react";

import HomeHero from "./HomeHero/HomeHero";

import Home2 from "./Home2";


function Home() {
  return (
    <>
    <HomeHero />
    <section>
      <Home2 />
    </section>
    </>
  );
}

export default Home;
