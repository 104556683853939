import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "antd";
import Tilt from "react-parallax-tilt";
import truckLoad from "../../../Assets/truck-load.png";
import "./HomeCompact.scss";

function CompactDesign() {
  return (
    <Container fluid className="home-about-section compact-design primary">
      <Container className="inner-container left">
        <h2>Compact Design: Efficiency Meets Sustainability</h2>
        <Row className="primary compact-container">
          <Col md={6}>
            <Row>
              {/* Box 1 */}
              <Col xs={12} sm={6} className="mb-4">
                <Card
                  title="100% Plastic-Free Packaging"
                  bordered={false}
                  style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                >
                  <p>
                    Fully plastic-free packaging, reducing environmental waste and promoting sustainability.
                  </p>
                </Card>
              </Col>
              {/* Box 2 */}
              <Col xs={12} sm={6} className="mb-4">
                <Card
                  title="94% Less Transportation"
                  bordered={false}
                  style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                >
                  <p>
                    Lightweight and compact design significantly lowers emissions during transportation.
                  </p>
                </Card>
              </Col>
            </Row>
            <Row>
              {/* Box 3 */}
              <Col xs={12} sm={6} className="mb-4">
                <Card
                  title="Space-Saving Innovation"
                  bordered={false}
                  style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                >
                  <p>
                    Save storage space, optimizing handling and shipping for businesses of all sizes.
                  </p>
                </Card>
              </Col>
              {/* Box 4 */}
              <Col xs={12} sm={6} className="mb-4">
                <Card
                  title="Low-Carbon & Eco-Friendly"
                  bordered={false}
                  style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                >
                  <p>
                    Growing demand for sustainable products while minimizing your carbon footprint.
                  </p>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* Right Column with Image */}
          <Col md={6}>
            <Tilt>
              <img
                src={truckLoad}
                className="img-fluid"
                alt="Compact Design Truck Load"
              />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default CompactDesign;
